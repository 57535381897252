
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index7KgSZPrKaUMeta } from "/var/www/dev/oxia-website/pages/admin/index.vue?macro=true";
import { default as loginOMINsNFbUOMeta } from "/var/www/dev/oxia-website/pages/admin/login.vue?macro=true";
import { default as book_45your_45appointmentHatI6J9aiPMeta } from "/var/www/dev/oxia-website/pages/book-your-appointment.vue?macro=true";
import { default as employee_45wellnessnXJ2PKQnbuMeta } from "/var/www/dev/oxia-website/pages/employee-wellness.vue?macro=true";
import { default as general_45practitionerZ62CclkIB3Meta } from "/var/www/dev/oxia-website/pages/general-practitioner.vue?macro=true";
import { default as independent_45screening_45centreoVvXstENIsMeta } from "/var/www/dev/oxia-website/pages/independent-screening-centre.vue?macro=true";
import { default as indexkrxdGonSNRMeta } from "/var/www/dev/oxia-website/pages/index.vue?macro=true";
import { default as ophthalmologistXhonhX7GoWMeta } from "/var/www/dev/oxia-website/pages/ophthalmologist.vue?macro=true";
import { default as optometristbPJdWIBKgdMeta } from "/var/www/dev/oxia-website/pages/optometrist.vue?macro=true";
import { default as other5MS8jGegzcMeta } from "/var/www/dev/oxia-website/pages/other.vue?macro=true";
import { default as patientU79ndbh4fYMeta } from "/var/www/dev/oxia-website/pages/patient.vue?macro=true";
import { default as pharmacyRyntBB2zeAMeta } from "/var/www/dev/oxia-website/pages/pharmacy.vue?macro=true";
import { default as physicianHuPgqMyPRzMeta } from "/var/www/dev/oxia-website/pages/physician.vue?macro=true";
import { default as questions_45and_45answersAx1zQPCwxMMeta } from "/var/www/dev/oxia-website/pages/questions-and-answers.vue?macro=true";
import { default as registration_45completedlpkWT1PUsCMeta } from "/var/www/dev/oxia-website/pages/registration-completed.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    component: () => import("/var/www/dev/oxia-website/pages/admin/index.vue")
  },
  {
    name: "admin-login",
    path: "/admin/login",
    component: () => import("/var/www/dev/oxia-website/pages/admin/login.vue")
  },
  {
    name: "book-your-appointment",
    path: "/book-your-appointment",
    component: () => import("/var/www/dev/oxia-website/pages/book-your-appointment.vue")
  },
  {
    name: "employee-wellness",
    path: "/employee-wellness",
    component: () => import("/var/www/dev/oxia-website/pages/employee-wellness.vue")
  },
  {
    name: "general-practitioner",
    path: "/general-practitioner",
    component: () => import("/var/www/dev/oxia-website/pages/general-practitioner.vue")
  },
  {
    name: "independent-screening-centre",
    path: "/independent-screening-centre",
    component: () => import("/var/www/dev/oxia-website/pages/independent-screening-centre.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/dev/oxia-website/pages/index.vue")
  },
  {
    name: "ophthalmologist",
    path: "/ophthalmologist",
    component: () => import("/var/www/dev/oxia-website/pages/ophthalmologist.vue")
  },
  {
    name: "optometrist",
    path: "/optometrist",
    component: () => import("/var/www/dev/oxia-website/pages/optometrist.vue")
  },
  {
    name: "other",
    path: "/other",
    component: () => import("/var/www/dev/oxia-website/pages/other.vue")
  },
  {
    name: "patient",
    path: "/patient",
    component: () => import("/var/www/dev/oxia-website/pages/patient.vue")
  },
  {
    name: "pharmacy",
    path: "/pharmacy",
    component: () => import("/var/www/dev/oxia-website/pages/pharmacy.vue")
  },
  {
    name: "physician",
    path: "/physician",
    component: () => import("/var/www/dev/oxia-website/pages/physician.vue")
  },
  {
    name: "questions-and-answers",
    path: "/questions-and-answers",
    component: () => import("/var/www/dev/oxia-website/pages/questions-and-answers.vue")
  },
  {
    name: "registration-completed",
    path: "/registration-completed",
    component: () => import("/var/www/dev/oxia-website/pages/registration-completed.vue")
  }
]