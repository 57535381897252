import revive_payload_client_ShigSLPOVF from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.4.2_magicast@_f943398b1342a3e8c6aef296673a313c/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_XITeSzfbC9 from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.4.2_magicast@_f943398b1342a3e8c6aef296673a313c/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VsJPGTlknp from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.4.2_magicast@_f943398b1342a3e8c6aef296673a313c/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_orsqW61ICI from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.4.2_magicast@_f943398b1342a3e8c6aef296673a313c/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_9xtrAnCKMb from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.4.2_magicast@_f943398b1342a3e8c6aef296673a313c/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_opbSrWBfPi from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.4.2_magicast@_f943398b1342a3e8c6aef296673a313c/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_wkXVHOPblN from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.4.2_magicast@_f943398b1342a3e8c6aef296673a313c/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/dev/oxia-website/.nuxt/components.plugin.mjs";
import prefetch_client_7ykGSEUa7p from "/var/www/dev/oxia-website/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_ioredis@5.4.2_magicast@_f943398b1342a3e8c6aef296673a313c/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_ShigSLPOVF,
  unhead_XITeSzfbC9,
  router_VsJPGTlknp,
  payload_client_orsqW61ICI,
  navigation_repaint_client_9xtrAnCKMb,
  check_outdated_build_client_opbSrWBfPi,
  chunk_reload_client_wkXVHOPblN,
  components_plugin_KR1HBZs4kY,
  prefetch_client_7ykGSEUa7p
]